import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "d-flex align-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "mdi-arrow-left",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
                class: "mr-4"
              }),
              _createElementVNode("h1", null, _toDisplayString(_ctx.isEditing ? 'Edit' : 'Create') + " Property Management Company", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.submitForm, ["prevent"]),
        modelValue: _ctx.isFormValid,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isFormValid) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.propertyManagementCompany.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.propertyManagementCompany.name) = $event)),
            label: "Name",
            rules: _ctx.nameRules,
            required: "",
            name: "name"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.propertyManagementCompany.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.propertyManagementCompany.email) = $event)),
            label: "Email",
            type: "email",
            rules: _ctx.emailRules,
            name: "email"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.propertyManagementCompany.phone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.propertyManagementCompany.phone) = $event)),
            label: "Phone",
            name: "phone"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_btn, {
            type: "submit",
            color: "primary",
            disabled: !_ctx.isFormValid
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isEditing ? 'Save' : 'Create'), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["onSubmit", "modelValue"])
    ]),
    _: 1
  }))
}