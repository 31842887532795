<template>
  <v-app class="overflow-hidden">
    <v-app-bar app height="32" class="px-3">
      <v-app-bar-title class="text-subtitle-2">
        <router-link to="/" class="text-decoration-none text-subtitle-2 text-black">APM Help Reports</router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn to="/" text size="small">Home</v-btn>
      <v-btn @click="logout" text size="small">Logout</v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'App',
  setup() {
    const router = useRouter();
    const store = useStore();

    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const logout = () => {
      store.dispatch('logout');
      router.push('/login');
    };

    return {
      isAuthenticated,
      logout,
    };
  },
});
</script>

<style>
html,
body {
  overflow: hidden !important;
  height: 100%;
}

.v-application {
  height: 100vh;
  max-height: 100vh;
}

.v-main {
  height: calc(100vh - 32px);
  overflow-y: auto;
}
</style>
