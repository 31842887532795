<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <h1>{{ isEditing ? 'Edit' : 'Create' }} Property Management Company</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="submitForm" v-model="isFormValid">
      <v-text-field
        v-model="propertyManagementCompany.name"
        label="Name"
        :rules="nameRules"
        required
        name="name"
      ></v-text-field>
      <v-text-field
        v-model="propertyManagementCompany.email"
        label="Email"
        type="email"
        :rules="emailRules"
        name="email"
      ></v-text-field>
      <v-text-field
        v-model="propertyManagementCompany.phone"
        label="Phone"
        name="phone"
      ></v-text-field>
      <v-btn type="submit" color="primary" :disabled="!isFormValid">
        {{ isEditing ? 'Save' : 'Create' }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from '@/utils/axios';

export default defineComponent({
  name: 'CreateEditPmCompanyView',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const propertyManagementCompany = ref({
      name: '',
      email: '',
      phone: '',
    });

    const isFormValid = ref(false);
    const isEditing = computed(() => !!props.id);

    const nameRules = [(v: string) => !!v || 'Name is required'];
    const emailRules = [
      (v: string) => !v || /.+@.+\..+/.test(v) || 'Email must be valid',
    ];

    onMounted(async () => {
      if (isEditing.value) {
        try {
          const response = await axios.get(
            `/property-management-companies/${props.id}`
          );
          propertyManagementCompany.value = response.data;
        } catch (error) {
          console.error('Error fetching property management company:', error);
        }
      }
    });

    const submitForm = async () => {
      if (isFormValid.value) {
        try {
          if (isEditing.value) {
            await axios.put(
              `/property-management-companies/${props.id}`,
              propertyManagementCompany.value
            );
          } else {
            await axios.post(
              '/property-management-companies',
              propertyManagementCompany.value
            );
          }
          router.push('/');
        } catch (error) {
          console.error('Error submitting property management company:', error);
        }
      }
    };

    return {
      propertyManagementCompany,
      submitForm,
      isFormValid,
      isEditing,
      nameRules,
      emailRules,
    };
  },
});
</script>
