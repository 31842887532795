import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "overflow-hidden" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        app: "",
        height: "32",
        class: "px-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_title, { class: "text-subtitle-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: "/",
                class: "text-decoration-none text-subtitle-2 text-black"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("APM Help Reports")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            to: "/",
            text: "",
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Home")
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            onClick: _ctx.logout,
            text: "",
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Logout")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}