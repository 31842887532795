import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
import axios from '@/utils/axios';

const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

// Add global error handler to ignore ResizeObserver errors
const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
  // ignore error if in test environment
  if (process.env.NODE_ENV === 'test') {
    if (err instanceof Error && err.message.includes('ResizeObserver loop completed with undelivered notifications')) {
      // Ignore the error
      return;
    }
  }

  // Log other errors
  console.error(err, info);
};

app.use(store).use(router).use(vuetify).mount('#app');
