<template>
  <v-container fluid class="d-flex flex-column pa-0 h-100 overflow-hidden">
    <v-row class="px-4 pt-2 flex-grow-0">
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <v-card-title>Owner Statement</v-card-title>
      </v-col>
    </v-row>
    <v-alert
      v-if="!hasGeneralLedger"
      type="warning"
      class="ma-4 flex-grow-0 custom-alert"
      density="comfortable"
    >
      There is no General Ledger imported for this PM Company. Import one
      General Ledger to proceed.
    </v-alert>
    <v-form
      @submit.prevent="validateAndFetch"
      ref="form"
      class="mt-2 mb-2 px-4 w-100"
    >
      <v-row dense>
        <v-col cols="12" md="2">
          <v-select
            v-model="startMonth"
            :items="months"
            label="Start Month"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">
          <v-select
            v-model="startYear"
            :items="years"
            label="Start Year"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="endMonth"
            :items="months"
            label="End Month"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">
          <v-select
            v-model="endYear"
            :items="years"
            label="End Year"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="5">
          <v-autocomplete
            v-model="property"
            :items="properties"
            label="Property"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
            :loading="loadingProperties"
            v-model:search-input="propertySearch"
            @update:search="searchProperties"
            clearable
            data-cy="property-input-autocomplete"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            density="compact"
            type="submit"
            color="primary"
            block
            data-cy="fetch-owner-statement-button"
            >Fetch</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-alert
      v-if="errorMessage"
      type="error"
      closable
      style="flex-grow: 0"
      class="error-alert ma-4"
      density="compact"
    >
      {{ errorMessage }}
    </v-alert>
    <v-card
      v-if="ownerStatement"
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 owner-statement-card"
    >
      <v-card-text class="d-flex flex-column owner-statement-content pa-0">
        <v-row justify="end" class="mx-5 flex-grow-0">
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <v-btn
                  icon="mdi-file-export"
                  size="x-small"
                  @click="exportToCSV"
                  v-bind="props"
                  class="text-none"
                  variant="text"
                  elevation="0"
                  data-cy="export-to-csv-button"
                >
                </v-btn>
              </template>
              <span>Export to CSV</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table-server
          :headers="headers"
          :items="tableItems"
          class="owner-statement-table"
          :items-length="tableItems.length"
          :items-per-page="-1"
          fixed-header
          density="compact"
          hide-default-footer
          disable-sort
          data-cy="owner-statement-table"
        >
          <template v-slot:item="{ item }">
            <tr class="hover-highlight">
              <td>{{ item.values.date }}</td>
              <td>{{ item.values.description }}</td>
              <td>{{ item.values.details }}</td>
              <td class="text-right">
                {{ formatCurrency(item.values.income) }}
              </td>
              <td class="text-right">
                {{ formatCurrency(item.values.expense) }}
              </td>
              <td class="text-right">
                {{ formatCurrency(item.values.balance) }}
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import axios from '@/utils/axios';
import { OwnerStatement } from '@/types/types';
import { useRoute } from 'vue-router';

const hasGeneralLedger = ref(true);

const formatCurrency = (value: string): string => {
  if (value == null || value === '') return '';

  if (typeof Number(value) != 'number') return '#ERROR';

  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

interface TableItem {
  values: {
    date: string;
    description: string;
    details: string;
    income: string;
    expense: string;
    balance: string;
  };
}

export default defineComponent({
  name: 'OwnerStatementView',
  setup() {
    const form = ref(null);
    const startMonth = ref('01');
    const startYear = ref(2022);
    const endMonth = ref('12');
    const endYear = ref(2023);
    const property = ref('');
    const properties = ref<string[]>([]);
    const route = useRoute();
    const pmCompanyId = computed(() => route.params.pmCompanyId as string);
    const ownerStatement = ref<OwnerStatement | null>(null);
    const errorMessage = ref('');

    const months = [
      { value: '01', title: 'January' },
      { value: '02', title: 'February' },
      { value: '03', title: 'March' },
      { value: '04', title: 'April' },
      { value: '05', title: 'May' },
      { value: '06', title: 'June' },
      { value: '07', title: 'July' },
      { value: '08', title: 'August' },
      { value: '09', title: 'September' },
      { value: '10', title: 'October' },
      { value: '11', title: 'November' },
      { value: '12', title: 'December' },
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

    const validateDates = () => {
      const startDate = new Date(`${startYear.value}-${startMonth.value}-01`);
      const endDate = new Date(`${endYear.value}-${endMonth.value}-01`);
      return startDate <= endDate;
    };

    const validateAndFetch = async () => {
      errorMessage.value = '';
      const { valid } = await (form.value as any).validate();
      if (valid) {
        if (!validateDates()) {
          errorMessage.value =
            'Start date must be less than or equal to end date';
          return;
        }
        await fetchOwnerStatement();
      }
    };

    const fetchOwnerStatement = async () => {
      try {
        const startDate = `${startYear.value}-${startMonth.value}`;
        const endDate = `${endYear.value}-${endMonth.value}`;
        const response = await axios.get(`/owner-statement`, {
          params: {
            pmCompanyId: pmCompanyId.value,
            startDate,
            endDate,
            property: property.value,
          },
        });

        if (response.data.error) {
          errorMessage.value = response.data.error;
        } else {
          ownerStatement.value = response.data;
        }
      } catch (error) {
        console.error('Error fetching owner statement:', error);
        errorMessage.value =
          'Error fetching owner statement. Please try again.';
      }
    };

    const headers = computed(() => {
      return [
        { title: 'Date', key: 'date', width: '100px' },
        { title: 'Description', key: 'description', width: '250px' },
        { title: 'Details', key: 'details', width: '250px' },
        { title: 'Income', key: 'income', align: 'end' },
        { title: 'Expense', key: 'expense', align: 'end' },
        { title: 'Balance', key: 'balance', align: 'end' },
      ];
    });

    const tableItems = computed(() => {
      if (!ownerStatement.value) return [];
      return ownerStatement.value.entries.map((entry) => ({
        values: {
          date: entry.date,
          description: entry.description,
          details: entry.details,
          income: entry.income,
          expense: entry.expense,
          balance: entry.balance,
        },
      }));
    });

    const exportToCSV = () => {
      if (!ownerStatement.value) return;

      const headersCsvContent = headers.value
        .map((header) => header.title)
        .join(',');

      const bodyCsvContent = ownerStatement.value.entries
        .map((entry) => {
          return [
            entry.date,
            `"${entry.description}"`,
            `"${entry.details}"`,
            formatCurrency(entry.income),
            formatCurrency(entry.expense),
            formatCurrency(entry.balance),
          ].join(',');
        })
        .join('\n');

      const csvContent = [headersCsvContent, bodyCsvContent].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'owner_statement.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const loadingProperties = ref(false);
    const propertySearch = ref('');

    const fetchProperties = async () => {
      try {
        loadingProperties.value = true;
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/properties`
        );
        properties.value = response.data;
        hasGeneralLedger.value = properties.value.length > 0;
      } catch (error) {
        console.error('Error fetching properties:', error);
        errorMessage.value = 'Error fetching properties. Please try again.';
        hasGeneralLedger.value = false;
      } finally {
        loadingProperties.value = false;
      }
    };

    const checkGeneralLedger = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/general-ledger-statistics`
        );
        hasGeneralLedger.value = response.data.totalEntries > 0;
      } catch (error) {
        console.error('Error checking general ledger:', error);
        hasGeneralLedger.value = false;
      }
    };

    const searchProperties = (val: string) => {
      if (val && val.length > 2) {
        fetchProperties();
      }
    };

    onMounted(() => {
      fetchProperties();
      checkGeneralLedger();
    });

    return {
      form,
      startMonth,
      startYear,
      endMonth,
      endYear,
      property,
      properties,
      months,
      years,
      ownerStatement,
      errorMessage,
      validateAndFetch,
      formatCurrency,
      headers,
      tableItems,
      exportToCSV,
      loadingProperties,
      propertySearch,
      searchProperties,
      hasGeneralLedger,
    };
  },
});
</script>

<style scoped>
.text-right {
  text-align: right !important;
}

.table-container {
  width: 100%;
  overflow: auto;
}

.owner-statement-table {
  height: 100%;
}

.owner-statement-table :deep(.v-data-table__wrapper) {
  overflow: visible;
}

.owner-statement-table :deep(table) {
  border-collapse: separate;
  border-spacing: 0;
}

.owner-statement-table :deep(thead) {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.owner-statement-table :deep(th) {
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  background-color: white;
}

.owner-statement-table :deep(td),
.owner-statement-table :deep(th) {
  padding: 0 8px !important;
  height: 32px !important;
  font-size: 0.75rem !important;
  white-space: nowrap;
}

.owner-statement-table :deep(.v-data-table__wrapper) {
  font-size: 0.75rem;
}

.owner-statement-table :deep(.v-table__wrapper) {
  overflow-x: auto;
}

.owner-statement-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.owner-statement-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.owner-statement-table {
  flex: 1;
  overflow: auto;
}

.input-field {
  font-size: 0.7rem;
}

.input-field :deep(.v-field__input) {
  min-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-field :deep(.v-field__outline) {
  --v-field-border-width: 1px;
}

.input-field :deep(.v-field__input),
.input-field :deep(.v-select__selection-text),
.input-field :deep(.v-select__selection) {
  font-size: 0.7rem;
  margin-top: 2px;
}

.input-field :deep(.v-label) {
  font-size: 0.7rem;
}

.input-field :deep(.v-select__selection) {
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 960px) {
  .v-col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.error-alert {
  overflow: visible;
  white-space: normal;
  word-wrap: break-word;
  font-size: 0.7rem;
}

.hover-highlight:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-alert {
  min-height: 48px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
</style>
