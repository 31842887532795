import { createStore } from 'vuex';
import axios from '@/utils/axios';
import { jwtDecode } from 'jwt-decode';

export default createStore({
  state: {
    token: localStorage.getItem('token') || null,
    user: null,
  },
  getters: {
    isAuthenticated: (state) => {
      if (!state.token) return false;

      try {
        const decodedToken = jwtDecode(state.token);
        const currentTime = Date.now() / 1000;
        return (decodedToken.exp ?? 0) > currentTime;
      } catch {
        return false;
      }
    },
    getUser: (state) => state.user,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    clearAuthData(state) {
      state.token = null;
      state.user = null;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/login', credentials);
        const token = response.data.token;
        localStorage.setItem('token', token);
        commit('setToken', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return true;
      } catch (error) {
        console.error('Login failed:', error);
        return false;
      }
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      commit('clearAuthData');
    },
    checkAuth({ commit, state }) {
      if (!state.token) return false;

      try {
        const decodedToken = jwtDecode(state.token);
        const currentTime = Date.now() / 1000;

        if ((decodedToken.exp ?? 0) > currentTime) {
          return true;
        } else {
          // Token has expired
          commit('clearAuthData');
          return false;
        }
      } catch {
        // Invalid token
        commit('clearAuthData');
        return false;
      }
    },
  },
  modules: {},
});
