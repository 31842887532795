<template>
  <v-container fluid class="w-100">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <h1>Chart of Accounts</h1>
      </v-col>
    </v-row>
    <v-alert
      v-if="showAlert"
      type="error"
      closable
      @click:close="showAlert = false"
      class="mb-4"
    >
      {{ alertMessage }}
    </v-alert>
    <v-btn color="primary" class="mb-4" @click="openImportDialog">
      Import Chart of Accounts
    </v-btn>
    <v-card
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 chart-of-accounts-card"
    >
      <v-card-text class="d-flex flex-column chart-of-accounts-content pa-0">
        <v-data-table
          :headers="headers"
          :items="accounts"
          :sort-by="[{ key: 'number' }, { key: 'name' }]"
          class="chart-of-accounts-table"
          :items-length="accounts.length"
          :items-per-page="-1"
          fixed-header
          density="compact"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr class="hover-highlight">
              <td>{{ item.number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.type }}</td>
              <td>
                {{
                  item.subAccountOf
                    ? item.subAccountOf.number + ' ' + item.subAccountOf.name
                    : ''
                }}
              </td>
              <td>{{ item.offsetAccount }}</td>
              <td>{{ item.options }}</td>
              <td>{{ item.hidden ? 'Yes' : 'No' }}</td>
              <td>{{ formatDate(item.createdAt) }}</td>
              <td>{{ formatDate(item.updatedAt) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="importDialog" max-width="500px">
      <v-card>
        <v-card-title>Import Chart of Accounts</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedVendor"
            :items="vendors"
            item-title="text"
            item-value="value"
            label="Select Vendor"
            name="vendor"
            required
            :rules="[(v) => !!v || 'Vendor is required']"
          ></v-select>
          <v-file-input
            v-model="file"
            :label="fileInputLabel"
            :accept="fileAccept"
            name="file"
            :rules="[(v) => !!v || 'File is required']"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeImportDialog"
            >Cancel</v-btn
          >
          <v-btn
            data-cy="import-chart-of-accounts-button"
            color="blue darken-1"
            text
            @click="importChartOfAccounts"
            >Import</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import axios from '@/utils/axios';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'ChartOfAccountsView',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const headers = ref([
      { title: 'Number', key: 'number' },
      { title: 'Name', key: 'name' },
      { title: 'Type', key: 'type' },
      {
        title: 'Sub Account Of',
        key: 'subAccountOf',
        value: (item: any) => item.subAccountOf?.name || '',
      },
      { title: 'Offset Account', key: 'offsetAccount' },
      { title: 'Options', key: 'options' },
      { title: 'Hidden', key: 'hidden' },
      { title: 'Created At', key: 'createdAt' },
      { title: 'Updated At', key: 'updatedAt' },
    ]);

    const accounts = ref([]);
    const importDialog = ref(false);
    const file = ref(null);
    const alertMessage = ref('');
    const showAlert = ref(false);
    const selectedVendor = ref('');
    const vendors = [
      { text: 'AppFolio', value: 'appfolio' },
      { text: 'Propertyware', value: 'propertyware' },
    ];

    const fileInputLabel = computed(() => {
      return selectedVendor.value === 'propertyware'
        ? 'Select XLS file'
        : 'Select CSV file';
    });

    const fileAccept = computed(() => {
      return selectedVendor.value === 'propertyware' ? '.xls,.xlsx' : '.csv';
    });

    const fetchAccounts = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${props.id}/accounts`
        );
        accounts.value = response.data;
      } catch (error) {
        console.error('Error fetching accounts:', error);
        alertMessage.value = 'Error fetching accounts. Please try again.';
        showAlert.value = true;
      }
    };

    onMounted(fetchAccounts);

    const openImportDialog = () => {
      importDialog.value = true;
    };

    const closeImportDialog = () => {
      importDialog.value = false;
      file.value = null;
      selectedVendor.value = '';
    };

    const importChartOfAccounts = async () => {
      if (!file.value || !selectedVendor.value) return;

      const formData = new FormData();
      formData.append('file', file.value);
      formData.append('vendor', selectedVendor.value);

      try {
        await axios.post(
          `/property-management-companies/${props.id}/import-chart-of-accounts`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        await fetchAccounts();
        closeImportDialog();
      } catch (error) {
        console.error('Error importing chart of accounts:', error);
        alertMessage.value =
          'Error importing chart of accounts. Please try again.';
        showAlert.value = true;
      }
    };

    return {
      headers,
      accounts,
      importDialog,
      file,
      alertMessage,
      showAlert,
      openImportDialog,
      closeImportDialog,
      importChartOfAccounts,
      formatDate: (date: string) => dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      selectedVendor,
      vendors,
      fileInputLabel,
      fileAccept,
    };
  },
});
</script>

<style scoped>
.chart-of-accounts-table {
  height: 100%;
}

.chart-of-accounts-table :deep(.v-data-table__wrapper) {
  overflow: visible;
}

.chart-of-accounts-table :deep(table) {
  border-collapse: separate;
  border-spacing: 0;
}

.chart-of-accounts-table :deep(thead) {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.chart-of-accounts-table :deep(th) {
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  background-color: white;
}

.chart-of-accounts-table :deep(td),
.chart-of-accounts-table :deep(th) {
  padding: 0 8px !important;
  height: 32px !important;
  font-size: 0.75rem !important;
  white-space: nowrap;
}

.chart-of-accounts-table :deep(.v-data-table__wrapper) {
  font-size: 0.75rem;
}

.chart-of-accounts-table :deep(.v-table__wrapper) {
  overflow-x: auto;
}

.chart-of-accounts-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.chart-of-accounts-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.hover-highlight:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.v-select__content {
  max-width: 250px !important;
}
</style>
