<template>
  <v-container fluid class="d-flex flex-column pa-0 h-100 overflow-hidden">
    <CommonTableStyles />
    <v-row class="px-4 pt-2 flex-grow-0">
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <v-card-title>Income Statement</v-card-title>
      </v-col>
    </v-row>
    <v-alert
      v-if="!hasGeneralLedger"
      type="warning"
      class="ma-4 flex-grow-0 custom-alert"
      density="comfortable"
    >
      There is no General Ledger imported for this PM Company. Import one
      General Ledger to proceed.
    </v-alert>
    <v-form
      @submit.prevent="validateAndFetch"
      ref="form"
      class="mt-2 mb-2 px-4 w-100"
    >
      <v-row dense>
        <v-col cols="12" md="2">
          <v-select
            v-model="startMonth"
            :items="months"
            label="Start Month"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">
          <v-select
            v-model="startYear"
            :items="years"
            label="Start Year"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="endMonth"
            :items="months"
            label="End Month"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">
          <v-select
            v-model="endYear"
            :items="years"
            label="End Year"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
          ></v-select>
        </v-col>
        <v-col cols="12" md="5">
          <v-autocomplete
            v-model="property"
            :items="properties"
            label="Property"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
            :loading="loadingProperties"
            v-model:search-input="propertySearch"
            @update:search="searchProperties"
            clearable
            data-cy="property-input-autocomplete"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            density="compact"
            type="submit"
            color="primary"
            block
            data-cy="fetch-income-statement-button"
            >Fetch</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-alert
      v-if="errorMessage"
      type="error"
      closable
      style="flex-grow: 0"
      class="error-alert ma-4"
      density="compact"
    >
      {{ errorMessage }}
    </v-alert>
    <v-card
      v-if="incomeStatement"
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 table-card"
    >
      <v-card-text class="d-flex flex-column table-content pa-0">
        <v-row justify="end" class="mx-5 flex-grow-0">
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <v-btn
                  icon="mdi-file-export"
                  size="x-small"
                  @click="exportToCSV"
                  v-bind="props"
                  class="text-none"
                  variant="text"
                  elevation="0"
                  data-cy="export-to-csv-button"
                >
                </v-btn>
              </template>
              <span>Export to CSV</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableItems"
          class="scrollable-table"
          :items-length="tableItems.length"
          :items-per-page="-1"
          fixed-header
          density="compact"
          hide-default-footer
          disable-sort
          data-cy="income-statement-table"
        >
          <template v-slot:item="{ item }">
            <tr
              :class="{
                'font-weight-bold': item.isBold,
                'hover-highlight': true,
              }"
            >
              <td
                v-for="header in headers"
                :key="header.key"
                :class="{ 'text-right': header.key !== 'accountName' }"
              >
                {{
                  header.key === 'accountName'
                    ? item.values[header.key]
                    : formatCurrency(item.values[header.key])
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import CommonTableStyles from '@/components/CommonTableStyles.vue';
import axios from '@/utils/axios';
import {
  IncomeStatement,
  AccountGroup,
  AccountData,
  PropertiesResponse,
} from '@/types/types';
import { useRoute } from 'vue-router';

const hasGeneralLedger = ref(true);

const formatCurrency = (value: string): string => {
  if (value == null || value === '') return '';

  if (typeof Number(value) != 'number') return '#ERROR';

  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const formatMonthYear = (month: string): string => {
  const [year, monthNum] = month.split('-');
  const date = new Date(parseInt(year), parseInt(monthNum) - 1);
  return date.toLocaleString('en-US', {
    month: 'short',
    year: 'numeric',
  });
};

interface TableItem {
  values: {
    accountName: string;
    total?: string;
    [key: string]: string | undefined;
  };
  isBold?: boolean;
  isParentAccount?: boolean;
}

export default defineComponent({
  name: 'IncomeStatementView',
  components: {
    CommonTableStyles,
  },
  setup() {
    const form = ref(null);
    const startMonth = ref('01');
    const startYear = ref(2022);
    const endMonth = ref('12');
    const endYear = ref(2023);
    const property = ref('');
    const properties = ref<string[]>([]);
    const route = useRoute();
    const pmCompanyId = computed(() => route.params.pmCompanyId as string);
    const incomeStatement = ref<IncomeStatement | null>(null);
    const errorMessage = ref('');

    const months = [
      { value: '01', title: 'January' },
      { value: '02', title: 'February' },
      { value: '03', title: 'March' },
      { value: '04', title: 'April' },
      { value: '05', title: 'May' },
      { value: '06', title: 'June' },
      { value: '07', title: 'July' },
      { value: '08', title: 'August' },
      { value: '09', title: 'September' },
      { value: '10', title: 'October' },
      { value: '11', title: 'November' },
      { value: '12', title: 'December' },
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

    const validateDates = () => {
      const startDate = new Date(`${startYear.value}-${startMonth.value}-01`);
      const endDate = new Date(`${endYear.value}-${endMonth.value}-01`);
      return startDate <= endDate;
    };

    const validateAndFetch = async () => {
      errorMessage.value = '';
      const { valid } = await (form.value as any).validate();
      if (valid) {
        if (!validateDates()) {
          errorMessage.value =
            'Start date must be less than or equal to end date';
          return;
        }
        await fetchIncomeStatement();
      }
    };

    const fetchIncomeStatement = async () => {
      try {
        const startDate = `${startYear.value}-${startMonth.value}`;
        const endDate = `${endYear.value}-${endMonth.value}`;
        const response = await axios.get(`/income-statement`, {
          params: {
            pmCompanyId: pmCompanyId.value,
            startDate,
            endDate,
            property: property.value,
          },
        });

        if (response.data.error) {
          errorMessage.value = response.data.error;
        } else {
          incomeStatement.value = response.data;
        }
      } catch (error) {
        console.error('Error fetching income statement:', error);
        errorMessage.value =
          'Error fetching income statement. Please try again.';
      }
    };

    const headers = computed(() => {
      if (!incomeStatement.value) return [];
      return [
        { title: 'Account Name', key: 'accountName', width: '250px' },
        ...incomeStatement.value.months.map((month) => ({
          title: formatMonthYear(month),
          key: month,
          align: 'end',
        })),
        { title: 'Total', key: 'total', align: 'end' },
      ];
    });

    const createTableItem = (
      accountName: string,
      values: string[],
      total: string,
      isBold = false,
      isParentAccount = false
    ): TableItem => ({
      values: {
        accountName,
        ...Object.fromEntries(
          values.map((value, index) => [
            incomeStatement.value?.months[index] ?? '',
            value,
          ])
        ),
        total: total,
      },
      isBold,
      isParentAccount,
    });

    const processAccountGroup = (accountGroup: AccountGroup): TableItem[] => {
      const items: TableItem[] = [];
      accountGroup.subAccounts.forEach((subAccount: AccountData) => {
        items.push(
          createTableItem(
            subAccount.account,
            subAccount.values,
            subAccount.total
          )
        );
      });
      items.unshift(createTableItem(accountGroup.account, [], '', false, true));
      items.push(
        createTableItem(
          `Total ${accountGroup.account}`,
          accountGroup.total.values,
          accountGroup.total.total,
          true
        )
      );
      return items;
    };

    const tableItems = computed((): TableItem[] => {
      if (!incomeStatement.value) return [];

      const items: TableItem[] = [
        { values: { accountName: 'Operating Income & Expense' }, isBold: true },
        { values: { accountName: 'Income' }, isBold: true },
      ];

      incomeStatement.value.incomeAccounts.forEach((incomeAccount) => {
        items.push(...processAccountGroup(incomeAccount));
      });

      if (incomeStatement.value.totalOperatingIncome) {
        items.push(
          createTableItem(
            'Total Operating Income',
            incomeStatement.value.totalOperatingIncome.values,
            incomeStatement.value.totalOperatingIncome.total,
            true
          )
        );
      }

      items.push({ values: { accountName: 'Expense' }, isBold: true });

      incomeStatement.value.expenseAccounts.forEach((expenseAccount) => {
        items.push(...processAccountGroup(expenseAccount));
      });

      const totalItems = [
        { key: 'totalOperatingExpense', label: 'Total Operating Expense' },
        { key: 'netOperatingIncome', label: 'NOI - Net Operating Income' },
        { key: 'totalIncome', label: 'Total Income' },
        { key: 'totalExpense', label: 'Total Expense' },
        { key: 'netIncome', label: 'Net Income' },
      ];

      totalItems.forEach(({ key, label }) => {
        if (incomeStatement.value) {
          const item = incomeStatement.value[key as keyof IncomeStatement];
          if (item && 'values' in item && 'total' in item) {
            items.push(createTableItem(label, item.values, item.total, true));
          }
        }
      });

      return items;
    });

    const exportToCSV = () => {
      if (!incomeStatement.value) return;

      const getIndentation = (item: TableItem): string => {
        const accountName = item.values.accountName as string;
        if (accountName === 'Operating Income & Expense') return '';
        if (
          [
            'Income',
            'Total Operating Income',
            'Total Operating Expense',
            'Expense',
            'NOI - Net Operating Income',
            'Total Income',
            'Total Expense',
            'Net Income',
          ].includes(accountName)
        )
          return '    ';
        if (accountName.startsWith('Total ') || item.isParentAccount)
          return '        ';
        return '            ';
      };

      const headersCsvContent = headers.value
        .map((header) =>
          header.title.includes(',') ? `"${header.title}"` : header.title
        )
        .join(',');

      const bodyCsvContent = tableItems.value.map((item) => {
        const indentation = getIndentation(item);
        return headers.value
          .map((header, index) => {
            const value = item.values[header.key] ?? '';

            let result: string;
            if (index === 0) {
              result = `${indentation}${value}`;
            } else {
              result =
                typeof Number(value) === 'number'
                  ? formatCurrency(value)
                  : value;
            }

            return result.includes(',') ? `"${result}"` : result;
          })
          .join(',');
      });
      const csvContent = [headersCsvContent, ...bodyCsvContent].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'income_statement.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const loadingProperties = ref(false);
    const propertySearch = ref('');

    const fetchProperties = async () => {
      try {
        loadingProperties.value = true;
        const response = (await axios.get(
          `/property-management-companies/${pmCompanyId.value}/properties`
        )) as PropertiesResponse;
        if (response.data.vendor === 'appfolio') {
          properties.value = Object.values(response.data.portfolios).flatMap(
            (portfolio) => portfolio.properties
          );
        } else if (response.data.vendor === 'propertyware') {
          // Propertyware IncomeStatement is per Property
          properties.value = Object.values(response.data.portfolios).map(
            (p) => p.name
          );
        }

        hasGeneralLedger.value = properties.value.length > 0;
      } catch (error) {
        console.error('Error fetching properties:', error);
        errorMessage.value = 'Error fetching properties. Please try again.';
        hasGeneralLedger.value = false;
      } finally {
        loadingProperties.value = false;
      }
    };

    const checkGeneralLedger = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/general-ledger-statistics`
        );
        hasGeneralLedger.value = response.data.totalEntries > 0;
      } catch (error) {
        console.error('Error checking general ledger:', error);
        hasGeneralLedger.value = false;
      }
    };

    const searchProperties = (val: string) => {
      if (val && val.length > 2) {
        fetchProperties();
      }
    };

    onMounted(() => {
      fetchProperties();
      checkGeneralLedger();
    });

    return {
      form,
      startMonth,
      startYear,
      endMonth,
      endYear,
      property,
      properties,
      months,
      years,
      incomeStatement,
      errorMessage,
      validateAndFetch,
      formatCurrency,
      headers,
      tableItems,
      exportToCSV,
      loadingProperties,
      propertySearch,
      searchProperties,
      hasGeneralLedger,
    };
  },
});
</script>

<style scoped>
.text-right {
  text-align: right !important;
}

.input-field {
  font-size: 0.7rem;
}

.input-field :deep(.v-field__input) {
  min-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-field :deep(.v-field__outline) {
  --v-field-border-width: 1px;
}

.input-field :deep(.v-field__input),
.input-field :deep(.v-select__selection-text),
.input-field :deep(.v-select__selection) {
  font-size: 0.7rem;
  margin-top: 2px;
}

.input-field :deep(.v-label) {
  font-size: 0.7rem;
}

.input-field :deep(.v-select__selection) {
  margin-top: 0;
  margin-bottom: 0;
}

.error-alert {
  overflow: visible;
  white-space: normal;
  word-wrap: break-word;
  font-size: 0.7rem;
}

.hover-highlight:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-alert {
  min-height: 48px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
</style>
