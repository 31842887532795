<template>
  <v-container fluid class="d-flex flex-column pa-0 h-100 overflow-hidden">
    <CommonTableStyles />
    <v-row class="px-4 pt-2 flex-grow-0">
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <v-card-title>Lease Ledger</v-card-title>
      </v-col>
    </v-row>
    <v-alert
      v-if="!hasLeases"
      type="warning"
      class="ma-4 flex-grow-0 custom-alert"
      density="comfortable"
    >
      There are no leases imported for this PM Company. Import leases to
      proceed.
    </v-alert>
    <v-form
      @submit.prevent="validateAndFetch"
      ref="form"
      class="mt-2 mb-2 px-4 w-100"
    >
      <v-row dense>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="property"
            :items="properties"
            label="Unit / Property"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
            :loading="loadingProperties"
            v-model:search-input="unitPropertySearch"
            clearable
            data-cy="unit-property-input-autocomplete"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectedTenantName"
            :items="tenants"
            item-title="displayName"
            item-value="tenant"
            label="Tenant"
            variant="outlined"
            density="compact"
            :rules="[(v) => !!v || 'Required']"
            hide-details
            class="input-field"
            :loading="loadingTenants"
            clearable
            data-cy="tenant-input-autocomplete"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            density="compact"
            type="submit"
            color="primary"
            block
            data-cy="fetch-lease-ledger-button"
            >Fetch</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-alert
      v-if="errorMessage"
      type="error"
      closable
      style="flex-grow: 0"
      class="error-alert ma-4"
      density="compact"
    >
      {{ errorMessage }}
    </v-alert>
    <v-card
      v-if="leaseLedger"
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 table-card"
    >
      <v-card-text class="d-flex flex-column table-content pa-0">
        <v-row justify="end" class="mx-5 flex-grow-0">
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <v-btn
                  icon="mdi-file-export"
                  size="x-small"
                  @click="exportToCSV"
                  v-bind="props"
                  class="text-none"
                  variant="text"
                  elevation="0"
                  data-cy="export-to-csv-button"
                >
                </v-btn>
              </template>
              <span>Export to CSV</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableItems"
          class="scrollable-table"
          :items-length="tableItems.length"
          :items-per-page="-1"
          fixed-header
          density="compact"
          hide-default-footer
          disable-sort
          data-cy="lease-ledger-table"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td
                v-for="header in headers"
                :key="header.key"
                :class="{
                  'text-right': numericColumns.includes(header.key),
                  nowrap: ['date', 'depositDate'].includes(header.key),
                }"
              >
                {{
                  numericColumns.includes(header.key)
                    ? formatCurrency(item[header.key])
                    : ['date', 'depositDate'].includes(header.key)
                      ? formatDate(item[header.key])
                      : item[header.key]
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import CommonTableStyles from '@/components/CommonTableStyles.vue';
import axios from '@/utils/axios';
import { useRoute } from 'vue-router';
import { leaseLedgerResponse, PropertiesResponse } from '@/types/types';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';

const formatCurrency = (value: string): string => {
  if (value == null || value === '') return '';
  if (typeof Number(value) != 'number') return '#ERROR';
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const formatDate = (dateString: string): string => {
  if (!dateString) return '';

  return format(parseISO(dateString), 'MM/dd/yyyy');
};

interface Tenant {
  tenant: string;
  startDate: string;
  endDate: string;
  displayName: string;
}

export default defineComponent({
  name: 'LeaseLedgerView',
  components: {
    CommonTableStyles,
  },
  setup() {
    const form = ref(null);
    const property = ref('');
    const selectedTenantName = ref<string | null>(null);
    const route = useRoute();
    const pmCompanyId = computed(() => route.params.pmCompanyId as string);
    const leaseLedger = ref<leaseLedgerResponse | null>(null);
    const errorMessage = ref('');
    const numericColumns = ['charges', 'payments', 'balance'];

    const tenants = ref<Tenant[]>([]);

    const selectedTenant = computed(() => {
      return (
        tenants.value.find((t) => t.tenant === selectedTenantName.value) || null
      );
    });

    const validateAndFetch = async () => {
      errorMessage.value = '';
      const { valid } = await (form.value as any).validate();
      if (valid && selectedTenant.value) {
        await fetchLeaseLedger();
      }
    };

    const fetchLeaseLedger = async () => {
      try {
        if (!selectedTenant.value) {
          errorMessage.value = 'Please select a tenant';
          return;
        }

        const response = await axios.get(`/lease-ledger`, {
          params: {
            pmCompanyId: pmCompanyId.value,
            startDate: selectedTenant.value.startDate,
            endDate: selectedTenant.value.endDate,
            property: property.value,
            tenant: selectedTenant.value.tenant,
          },
        });

        if (response.data.error) {
          errorMessage.value = response.data.error;
        } else {
          leaseLedger.value = response.data as leaseLedgerResponse;
        }
      } catch (error) {
        console.error('Error fetching lease ledger:', error);
        errorMessage.value = 'Error fetching lease ledger. Please try again.';
      }
    };

    const headers = ref([
      { title: 'Transaction', key: 'transaction' },
      { title: 'Date', key: 'date' },
      { title: 'Ref #', key: 'reference' },
      { title: 'Description', key: 'description' },
      { title: 'Payer Name', key: 'payerName' },
      { title: 'Deposit Date', key: 'depositDate' },
      { title: 'Charges', key: 'charges' },
      { title: 'Payments', key: 'payments' },
      { title: 'Balance', key: 'balance' },
    ]);

    const tableItems = computed(() => {
      if (!leaseLedger.value) return [];

      return leaseLedger.value.entries;
    });

    const exportToCSV = () => {
      if (!leaseLedger.value) return;

      const headersCsvContent = headers.value
        .map((header) => header.title)
        .join(',');

      const bodyCsvContent = tableItems.value.map((item: any) =>
        headers.value
          .map((header) => {
            const value = item[header.key];
            return numericColumns.includes(header.key)
              ? formatCurrency(value)
              : value;
          })
          .join(',')
      );

      const csvContent = [headersCsvContent, ...bodyCsvContent].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'lease_ledger.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const loadingProperties = ref(false);
    const unitPropertySearch = ref('');
    const properties = ref<string[]>([]);

    const loadingTenants = ref(false);

    const hasLeases = ref(true);

    const fetchProperties = async () => {
      try {
        loadingProperties.value = true;
        const response = (await axios.get(
          `/property-management-companies/${pmCompanyId.value}/properties`
        )) as PropertiesResponse;
        properties.value = Object.values(response.data.portfolios).flatMap(
          (portfolio) => portfolio.properties
        );
        hasLeases.value = properties.value.length > 0;
      } catch (error) {
        console.error('Error fetching unit properties:', error);
        errorMessage.value =
          'Error fetching unit properties. Please try again.';
        hasLeases.value = false;
      } finally {
        loadingProperties.value = false;
      }
    };

    const fetchTenants = async () => {
      if (!property.value) {
        tenants.value = [];
        return;
      }
      try {
        loadingTenants.value = true;
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/tenants`,
          {
            params: { property: property.value },
          }
        );
        tenants.value = response.data.map((tenant: Tenant) => ({
          ...tenant,
          displayName: `${tenant.startDate} - ${tenant.endDate} / ${tenant.tenant}`,
        }));
      } catch (error) {
        console.error('Error fetching tenants:', error);
        errorMessage.value = 'Error fetching tenants. Please try again.';
        tenants.value = [];
      } finally {
        loadingTenants.value = false;
      }
    };

    // const searchUnitProperties = (val: string) => {
    //   if (val && val.length > 2) {
    //     fetchProperties();
    //   }
    // };

    onMounted(() => {
      fetchProperties();
    });

    watch(property, () => {
      selectedTenantName.value = null; // Clear tenant when property changes
      fetchTenants(); // Fetch tenants immediately when property changes
    });

    return {
      form,
      property,
      selectedTenantName,
      tenants,
      errorMessage,
      validateAndFetch,
      leaseLedger,
      formatCurrency,
      formatDate,
      headers,
      tableItems,
      exportToCSV,
      loadingProperties,
      unitPropertySearch,
      // searchUnitProperties,
      loadingTenants,
      hasLeases,
      properties,
      numericColumns,
    };
  },
});
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
