<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <h1>Import General Ledger</h1>
      </v-col>
    </v-row>
    <v-alert
      v-if="!hasChartOfAccounts"
      type="warning"
      class="ma-4 flex-grow-0 custom-alert"
      density="comfortable"
    >
      There is no Chart of Accounts imported for this PM Company. Import a Chart
      of Accounts to proceed.
    </v-alert>
    <v-btn
      color="primary"
      class="mb-4"
      @click="openImportDialog"
      :disabled="!hasChartOfAccounts"
      data-cy="import-general-ledger-button"
    >
      Import General Ledger
    </v-btn>
    <v-card class="mt-4">
      <v-card-title>General Ledger Statistics</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <div class="text-h6 mb-1">
                  <v-icon
                    icon="mdi-file-document-multiple"
                    class="mr-2"
                  ></v-icon>
                  Total Entries
                </div>
                <div class="text-h4 font-weight-bold">
                  {{ statistics.totalEntries }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <div class="text-h6 mb-1">
                  <v-icon icon="mdi-home-group" class="mr-2"></v-icon>
                  Number of Properties
                </div>
                <div class="text-h4 font-weight-bold">
                  {{ statistics.numberOfProperties }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <div class="text-h6 mb-1">
                  <v-icon icon="mdi-calendar-clock" class="mr-2"></v-icon>
                  Last Entry Date
                </div>
                <div class="text-h4 font-weight-bold">
                  {{
                    statistics.lastEntryDate
                      ? formatDate(statistics.lastEntryDate)
                      : '-'
                  }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="importDialog" max-width="500px">
      <v-card>
        <v-card-title>Import General Ledger</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedVendor"
            :items="vendors"
            item-title="text"
            item-value="value"
            label="Select Vendor"
            name="vendor"
            required
            :rules="[(v) => !!v || 'Vendor is required']"
          ></v-select>
          <v-file-input
            v-if="selectedVendor === 'appfolio'"
            v-model="file"
            label="Select CSV file"
            accept=".csv"
            :rules="[(v) => !!v || 'File is required']"
            :disabled="loading"
          ></v-file-input>
          <v-file-input
            v-else
            v-model="files"
            label="Select XLSX files"
            accept=".xlsx"
            multiple
            :rules="[(v) => !!v || 'At least one file is required']"
            :disabled="loading"
            hint="Select 1 or more .xlsx Propertyware exported files"
          ></v-file-input>
        </v-card-text>
        <v-card-text v-if="loading">
          <v-progress-linear v-model="progress" color="primary" height="25">
            <strong>{{ Math.ceil(progress) }}%</strong>
          </v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeImportDialog"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="importGeneralLedger"
            :loading="loading"
            :disabled="
              (!file && selectedVendor === 'appfolio') ||
              (files.length === 0 && selectedVendor === 'propertyware') ||
              loading
            "
            data-cy="import-general-ledger-modal-button"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-alert
      v-model="successAlert"
      type="success"
      dismissible
      class="mt-4"
      data-cy="general-ledger-success-alert"
    >
      <pre>{{ successAlertText }}</pre>
    </v-alert>

    <v-alert
      v-model="errorAlert"
      type="error"
      dismissible
      class="mt-4 text-pre-wrap"
    >
      {{ errorAlertText }}
    </v-alert>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import axios from '@/utils/axios';
import { isCancel, isAxiosError } from 'axios';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'ImportGeneralLedgerView',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const importDialog = ref(false);
    const file = ref(null);
    const loading = ref(false);
    const snackbar = ref(false);
    const snackbarText = ref('');
    const successAlert = ref(false);
    const successAlertText = ref('');
    const errorAlert = ref(false);
    const errorAlertText = ref('');
    const statistics = ref({
      totalEntries: 0,
      numberOfProperties: 0,
      lastEntryDate: '',
    });
    const hasChartOfAccounts = ref(true);
    const selectedVendor = ref('');
    const vendors = [
      { text: 'AppFolio', value: 'appfolio' },
      { text: 'Propertyware', value: 'propertyware' },
    ];
    const files = ref<File[]>([]);

    const fetchStatistics = async () => {
      try {
        loading.value = true;
        const response = await axios.get(
          `/property-management-companies/${props.id}/general-ledger-statistics`
        );
        statistics.value = response.data;
      } catch (error) {
        console.error('Error fetching general ledger statistics:', error);
        showSnackbar('Error fetching general ledger statistics');
      } finally {
        loading.value = false;
      }
    };

    onMounted(fetchStatistics);

    const openImportDialog = () => {
      importDialog.value = true;
    };

    const closeImportDialog = () => {
      importDialog.value = false;
      file.value = null;
      files.value = [];
      selectedVendor.value = '';
    };

    const showSnackbar = (text: string) => {
      snackbarText.value = text;
      snackbar.value = true;
    };

    const showSuccessAlert = (text: string) => {
      successAlertText.value = text;
      successAlert.value = true;
      errorAlert.value = false;
    };

    const showErrorAlert = (text: string) => {
      errorAlertText.value = text;
      errorAlert.value = true;
      successAlert.value = false;
    };

    const progress = ref(0);

    const importGeneralLedger = async () => {
      loading.value = true;
      progress.value = 0;
      try {
        const formData = new FormData();
        if (selectedVendor.value === 'appfolio') {
          if (!file.value) return;
          formData.append('files', file.value);
        } else {
          if (files.value.length === 0) return;
          files.value.forEach((file) => {
            formData.append('files', file);
          });
        }
        formData.append('vendor', selectedVendor.value);

        await axios.post(
          `/property-management-companies/${props.id}/import-general-ledger`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            // signal: controller.signal,
            responseType: 'text',
            onDownloadProgress: (progressEvent) => {
              const responseText = progressEvent.event.target.responseText;
              const events = responseText.split('\n\n');
              for (const event of events) {
                if (event.trim() === '') continue;
                const data = JSON.parse(event.replace('data: ', ''));

                if (data.progress !== undefined) {
                  progress.value = data.progress;
                } else if (data.done) {
                  const { importSummary } = data;
                  showSuccessAlert(
                    `Import completed successfully\nTotal Entries: ${importSummary.totalEntries}\nImported Entries: ${importSummary.importedEntries}\nSkipped Entries: ${importSummary.skippedEntries}`
                  );
                  fetchStatistics();
                } else if (data.error) {
                  showErrorAlert(
                    data.error || 'Error importing general ledger'
                  );
                }
              }
            },
          }
        );
      } catch (error) {
        console.error('Error importing general ledger:', error);
        showErrorAlert('Error importing general ledger');
      } finally {
        closeImportDialog();
        loading.value = false;
        progress.value = 0;
      }
    };

    const formatDate = (dateString: string) => {
      if (dateString === 'N/A') return 'N/A';
      return dayjs(dateString).format('MMM D, YYYY');
    };

    const checkChartOfAccounts = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${props.id}/accounts`
        );
        hasChartOfAccounts.value = response.data.length > 0;
      } catch (error) {
        console.error('Error checking Chart of Accounts:', error);
        hasChartOfAccounts.value = false;
      }
    };

    onMounted(() => {
      fetchStatistics();
      checkChartOfAccounts();
    });

    return {
      importDialog,
      file,
      loading,
      snackbar,
      snackbarText,
      successAlert,
      successAlertText,
      errorAlert,
      errorAlertText,
      statistics,
      openImportDialog,
      closeImportDialog,
      importGeneralLedger,
      fetchStatistics,
      formatDate,
      hasChartOfAccounts,
      progress,
      selectedVendor,
      vendors,
      files,
    };
  },
});
</script>

<style scoped>
.custom-alert {
  min-height: 48px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
</style>
