<template>
  <v-container class="import-files-container">
    <v-row>
      <v-col cols="12" class="d-flex align-center mb-4">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
          data-cy="back-button"
        ></v-btn>
        <h1 class="text-h5">Import Files</h1>
      </v-col>
    </v-row>

    <!-- Vendor Selection Card -->
    <v-card class="mb-4 pa-4">
      <v-card-title class="text-h6 pb-2">Select Vendor</v-card-title>
      <v-select
        v-model="selectedVendor"
        :items="vendors"
        item-title="text"
        item-value="value"
        label="Select Vendor"
        name="vendor"
        required
        :rules="[(v) => !!v || 'Vendor is required']"
        density="compact"
        :disabled="isVendorDisabled"
        @update:modelValue="updateVendor"
        data-cy="vendor-select"
      ></v-select>
    </v-card>

    <!-- Chart of Accounts Card -->
    <v-card class="mb-4 pa-4">
      <v-card-title class="text-h6 pb-2">Chart of Accounts</v-card-title>
      <v-row align="center" no-gutters>
        <v-col cols="9">
          <v-file-input
            v-model="chartOfAccountsFile"
            :label="chartOfAccountsFileLabel"
            :accept="chartOfAccountsFileAccept"
            @change="onChartOfAccountsFileChange"
            density="compact"
            hide-details
            data-cy="chart-of-accounts-file-input"
          ></v-file-input>
        </v-col>
        <v-col cols="3" class="pl-2">
          <v-btn
            color="primary"
            @click="importChartOfAccounts"
            :disabled="!chartOfAccountsFile"
            block
            data-cy="import-chart-of-accounts-button"
          >
            Import
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="6">
          <div class="text-caption">Number of Accounts:</div>
          <div class="text-body-2 font-weight-medium">
            {{ chartOfAccountsStats.totalAccounts }}
          </div>
        </v-col>
        <v-col cols="6">
          <div class="text-caption">Last Import:</div>
          <div class="text-body-2 font-weight-medium">
            {{ formatDate(chartOfAccountsStats.lastImportDate) }}
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- General Ledger Card -->
    <v-card class="pa-4">
      <v-card-title class="text-h6 pb-2">General Ledger</v-card-title>
      <v-row align="center" no-gutters>
        <v-col cols="9">
          <v-file-input
            v-model="generalLedgerFile"
            :label="generalLedgerFileLabel"
            :accept="generalLedgerFileAccept"
            :multiple="selectedVendor === 'propertyware'"
            @change="onGeneralLedgerFileChange"
            density="compact"
            hide-details
            data-cy="general-ledger-file-input"
          ></v-file-input>
        </v-col>
        <v-col cols="3" class="pl-2">
          <v-btn
            color="primary"
            @click="importGeneralLedger"
            :disabled="!generalLedgerFile"
            block
            data-cy="import-general-ledger-button"
          >
            Import
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="4">
          <div class="text-caption">Total Entries:</div>
          <div class="text-body-2 font-weight-medium">
            {{ generalLedgerStats.totalEntries }}
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption">Number of Properties:</div>
          <div class="text-body-2 font-weight-medium">
            {{ generalLedgerStats.numberOfProperties }}
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption">Last Entry Date:</div>
          <div class="text-body-2 font-weight-medium">
            {{ formatDate(generalLedgerStats.lastEntryDate) }}
          </div>
        </v-col>
      </v-row>
      <v-alert
        v-model="successAlert"
        type="success"
        dismissible
        class="mt-4"
        data-cy="general-ledger-success-alert"
      >
        <pre data-cy="import-success-alert">{{ successAlertText }}</pre>
      </v-alert>
    </v-card>

    <v-card v-if="selectedVendor === 'propertyware'" class="mt-4 pa-4">
      <v-card-title class="text-h6 pb-2">Money In Payments</v-card-title>
      <v-row align="center" no-gutters>
        <v-col cols="9">
          <v-file-input
            v-model="moneyInPaymentsFile"
            label="Select XLS file"
            accept=".xls,.xlsx"
            @change="onMoneyInPaymentsFileChange"
            density="compact"
            hide-details
            data-cy="money-in-payments-file-input"
          ></v-file-input>
        </v-col>
        <v-col cols="3" class="pl-2">
          <v-btn
            color="primary"
            @click="importMoneyInPayments"
            :disabled="!moneyInPaymentsFile"
            block
            data-cy="import-money-in-payments-button"
          >
            Import
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="6">
          <div class="text-caption">Total Entries:</div>
          <div class="text-body-2 font-weight-medium">
            {{ moneyInPaymentsStats.totalEntries }}
          </div>
        </v-col>
        <v-col cols="6">
          <div class="text-caption">Last Import:</div>
          <div class="text-body-2 font-weight-medium">
            {{ formatDate(moneyInPaymentsStats.lastImportDate) }}
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="selectedVendor === 'propertyware'" class="mt-4 pa-4">
      <v-card-title class="text-h6 pb-2">Leases</v-card-title>
      <v-row align="center" no-gutters>
        <v-col cols="9">
          <v-file-input
            v-model="leasesFile"
            label="Select XLS file"
            accept=".xls,.xlsx"
            @change="onLeasesFileChange"
            density="compact"
            hide-details
            data-cy="leases-file-input"
          ></v-file-input>
        </v-col>
        <v-col cols="3" class="pl-2">
          <v-btn
            color="primary"
            @click="importLeases"
            :disabled="!leasesFile"
            block
            data-cy="import-leases-button"
          >
            Import
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="6">
          <div class="text-caption">Total Entries:</div>
          <div class="text-body-2 font-weight-medium">
            {{ leasesStats.totalEntries }}
          </div>
        </v-col>
        <v-col cols="6">
          <div class="text-caption">Last Import:</div>
          <div class="text-body-2 font-weight-medium">
            {{ formatDate(leasesStats.lastImportDate) }}
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn
          color="blue"
          text
          @click="snackbar = false"
          data-cy="close-snackbar-button"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import axios from '@/utils/axios';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'ImportFilesView',
  setup() {
    const route = useRoute();
    const pmCompanyId = route.params.id as string;

    const selectedVendor = ref('');
    const vendors = [
      { text: 'AppFolio', value: 'appfolio' },
      { text: 'Propertyware', value: 'propertyware' },
    ];

    const isVendorDisabled = ref(false);

    const chartOfAccountsFile = ref<File | null>(null);
    const generalLedgerFile = ref<File | File[] | null>(null);

    const chartOfAccountsStats = ref({
      totalAccounts: 0,
      lastImportDate: null as string | null,
    });

    const generalLedgerStats = ref({
      totalEntries: 0,
      numberOfProperties: 0,
      lastEntryDate: null as string | null,
    });

    const successAlert = ref(false);
    const successAlertText = ref('');

    const snackbar = ref(false);
    const snackbarText = ref('');

    const chartOfAccountsFileLabel = computed(() => {
      return selectedVendor.value === 'propertyware'
        ? 'Select XLS file'
        : 'Select CSV file';
    });

    const chartOfAccountsFileAccept = computed(() => {
      return selectedVendor.value === 'propertyware' ? '.xls,.xlsx' : '.csv';
    });

    const generalLedgerFileLabel = computed(() => {
      return selectedVendor.value === 'propertyware'
        ? 'Select XLSX files'
        : 'Select CSV file';
    });

    const generalLedgerFileAccept = computed(() => {
      return selectedVendor.value === 'propertyware' ? '.xlsx' : '.csv';
    });

    const moneyInPaymentsFile = ref<File | null>(null);
    const moneyInPaymentsStats = ref({
      totalEntries: 0,
      lastImportDate: null as string | null,
    });

    const leasesFile = ref<File | null>(null);
    const leasesStats = ref({
      totalEntries: 0,
      lastImportDate: null as string | null,
    });

    const formatDate = (date: string | null) => {
      return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : 'N/A';
    };

    const showSnackbar = (text: string) => {
      snackbarText.value = text;
      snackbar.value = true;
    };

    const onChartOfAccountsFileChange = () => {
      // Reset stats when file changes
      chartOfAccountsStats.value = {
        totalAccounts: 0,
        lastImportDate: null,
      };
    };

    const onGeneralLedgerFileChange = () => {
      // Reset stats when file changes
      generalLedgerStats.value = {
        totalEntries: 0,
        numberOfProperties: 0,
        lastEntryDate: null,
      };
    };

    const onMoneyInPaymentsFileChange = () => {
      // Reset stats when file changes
      moneyInPaymentsStats.value = {
        totalEntries: 0,
        lastImportDate: null,
      };
    };

    const onLeasesFileChange = () => {
      // Reset stats when file changes
      leasesStats.value = {
        totalEntries: 0,
        lastImportDate: null,
      };
    };

    const updateVendor = async () => {
      try {
        await axios.put(
          `/property-management-companies/${pmCompanyId}/vendor`,
          {
            vendor: selectedVendor.value,
          }
        );
        showSnackbar('Vendor updated successfully');
      } catch (error) {
        console.error('Error updating vendor:', error);
        showSnackbar('Error updating vendor. Please try again.');
      }
    };

    const fetchPmCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId}`
        );
        selectedVendor.value = response.data.vendor || '';
        isVendorDisabled.value = response.data.hasChartOfAccounts;
      } catch (error) {
        console.error('Error fetching PM company details:', error);
        showSnackbar('Error fetching company details. Please try again.');
      }
    };

    onMounted(async () => {
      await fetchPmCompanyDetails();
      fetchChartOfAccountsStats();
      fetchGeneralLedgerStats();

      if (selectedVendor.value === 'propertyware') {
        fetchMoneyInPaymentsStats();
        fetchLeasesStats();
      }
    });

    const importChartOfAccounts = async () => {
      if (!chartOfAccountsFile.value || !selectedVendor.value) return;

      const formData = new FormData();
      formData.append('file', chartOfAccountsFile.value);
      formData.append('vendor', selectedVendor.value);

      try {
        await axios.post(
          `/property-management-companies/${pmCompanyId}/import-chart-of-accounts`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        showSnackbar('Chart of Accounts imported successfully');
        fetchChartOfAccountsStats();
        isVendorDisabled.value = true; // Disable vendor selection after successful import
      } catch (error) {
        console.error('Error importing chart of accounts:', error);
        showSnackbar('Error importing chart of accounts. Please try again.');
      }
    };

    const importGeneralLedger = async () => {
      if (!generalLedgerFile.value || !selectedVendor.value) return;

      const formData = new FormData();
      if (Array.isArray(generalLedgerFile.value)) {
        generalLedgerFile.value.forEach((file) => {
          formData.append('files', file);
        });
      } else {
        formData.append('files', generalLedgerFile.value);
      }
      formData.append('vendor', selectedVendor.value);

      try {
        const response = await axios.post(
          `/property-management-companies/${pmCompanyId}/import-general-ledger`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'text',
            onDownloadProgress: (progressEvent) => {
              const responseText = progressEvent.event.target.responseText;
              const events = responseText.split('\n\n');
              for (const event of events) {
                if (event.trim() === '') continue;
                const data = JSON.parse(event.replace('data: ', ''));

                if (data.done) {
                  const { importSummary } = data;
                  successAlertText.value = `Import completed successfully\nTotal Entries: ${importSummary.totalEntries}\nImported Entries: ${importSummary.importedEntries}\nSkipped Entries: ${importSummary.skippedEntries}`;
                  successAlert.value = true;
                  fetchGeneralLedgerStats();
                } else if (data.error) {
                  showSnackbar(data.error || 'Error importing general ledger');
                }
              }
            },
          }
        );
      } catch (error) {
        console.error('Error importing general ledger:', error);
        showSnackbar('Error importing general ledger. Please try again.');
      }
    };

    const fetchChartOfAccountsStats = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId}/chart-of-accounts-statistics`
        );
        chartOfAccountsStats.value = response.data;
      } catch (error) {
        console.error('Error fetching chart of accounts statistics:', error);
      }
    };

    const fetchGeneralLedgerStats = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId}/general-ledger-statistics`
        );
        generalLedgerStats.value = response.data;
      } catch (error) {
        console.error('Error fetching general ledger statistics:', error);
      }
    };

    const importMoneyInPayments = async () => {
      if (!moneyInPaymentsFile.value || selectedVendor.value !== 'propertyware')
        return;

      const formData = new FormData();
      formData.append('file', moneyInPaymentsFile.value);

      try {
        await axios.post(
          `/property-management-companies/${pmCompanyId}/import-money-in-payments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        showSnackbar('Money In Payments imported successfully');
        fetchMoneyInPaymentsStats();
      } catch (error) {
        console.error('Error importing Money In Payments:', error);
        showSnackbar('Error importing Money In Payments. Please try again.');
      }
    };

    const fetchMoneyInPaymentsStats = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId}/money-in-payments-statistics`
        );
        moneyInPaymentsStats.value = response.data;
      } catch (error) {
        console.error('Error fetching Money In Payments statistics:', error);
      }
    };

    const importLeases = async () => {
      if (!leasesFile.value || selectedVendor.value !== 'propertyware') return;

      const formData = new FormData();
      formData.append('file', leasesFile.value);

      try {
        await axios.post(
          `/property-management-companies/${pmCompanyId}/import-leases`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        showSnackbar('Leases imported successfully');
        fetchLeasesStats();
      } catch (error) {
        console.error('Error importing Leases:', error);
        showSnackbar('Error importing Leases. Please try again.');
      }
    };

    const fetchLeasesStats = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId}/leases-statistics`
        );
        leasesStats.value = response.data;
      } catch (error) {
        console.error('Error fetching Leases statistics:', error);
      }
    };

    return {
      selectedVendor,
      vendors,
      isVendorDisabled,
      chartOfAccountsFile,
      generalLedgerFile,
      chartOfAccountsStats,
      generalLedgerStats,
      chartOfAccountsFileLabel,
      chartOfAccountsFileAccept,
      generalLedgerFileLabel,
      generalLedgerFileAccept,
      formatDate,
      snackbar,
      snackbarText,
      onChartOfAccountsFileChange,
      onGeneralLedgerFileChange,
      updateVendor,
      importChartOfAccounts,
      importGeneralLedger,
      moneyInPaymentsFile,
      moneyInPaymentsStats,
      onMoneyInPaymentsFileChange,
      importMoneyInPayments,
      leasesFile,
      leasesStats,
      onLeasesFileChange,
      importLeases,
      successAlert,
      successAlertText,
    };
  },
});
</script>

<style scoped>
.import-files-container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
